import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { graphql } from 'gatsby'
import { MDXProvider } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import LayoutFullWidth from '../../components/Layout/LayoutFullWidth'
import {
  Divider,
  Typography,
  MdxWrapper,
  AggregatorPin,
  PinBlockGroup,
  TransitionLink,
} from '../../components'
import homePin1 from '../../img/home/Home01.png'
import homePin2 from '../../img/home/Home02.png'
import homePin3 from '../../img/home/Home03.png'
import homePin4 from '../../img/home/Home04.png'
import styles from './IndexPageTemplate.module.css'
import Img from '../../components/gatsbyWrappers/Img'

const shortcodes = {
  Typography,
  Divider,
  MdxWrapper,
}

/** @todo update propTypes */
const propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export const IndexPageTemplate = ({ data }) => {
  const { page } = data
  const {
    intro,
    aggregatorsIntro,
    topStory1,
    topStory2,
    topStory3,
  } = page.frontmatter
  const topStories = [topStory1, topStory2, topStory3]

  const classes = classNames(['page-wrapper', 'container', styles.root])

  return (
    <div className={classes}>
      <MDXProvider components={shortcodes}>
        <div className={styles.intro}>
          <Typography tag="h1" className="mb-8">
            {intro.title}
          </Typography>
          <Typography tag="p" type="aggregatorIntro">
            {intro.text}
          </Typography>
          <Divider thickness={2} className="mt-8 mb-12" />
        </div>

        <Typography tag="h3" className="mb-8">
          Top Stories
        </Typography>
        <div className={styles.topStories}>
          {topStories.map(story => (
            <a href={story.url} key={story.title} className={styles.topStory}>
              <Img src={story.featuredImage} />
              <Typography className={styles.topStoryTitle} tag="h4">
                {story.title}
              </Typography>
            </a>
          ))}
        </div>

        <div className={styles.aggregatorsIntroContent}>
          <MdxWrapper>
            <MDXRenderer>{aggregatorsIntro.content}</MDXRenderer>
          </MdxWrapper>
        </div>

        <Divider thickness={2} className="mt-8 mb-12" />

        <div className={styles.aggregatorsIntro}>
          <Typography tag="h3" className="mb-8">
            Explore
          </Typography>
          <PinBlockGroup className={styles.aggregatorBlocks}>
            <AggregatorPin
              imageUrl={homePin2}
              text="Courses"
              kind="home"
              className={styles.aggregator}
            >
              <TransitionLink
                to="/educating"
                className="border-white text-white"
              >
                <span>Find out more</span>
              </TransitionLink>
            </AggregatorPin>
            <AggregatorPin
              imageUrl={homePin3}
              text="Events"
              kind="home"
              className={styles.aggregator}
            >
              <TransitionLink
                to="/convening"
                className="border-white text-white"
              >
                <span>Find out more</span>
              </TransitionLink>
            </AggregatorPin>
            <AggregatorPin
              imageUrl={homePin4}
              text="Catalysing"
              kind="home"
              className={styles.aggregator}
            >
              <TransitionLink
                to="/catalysing"
                className="border-white text-white"
              >
                <span>Find out more</span>
              </TransitionLink>
            </AggregatorPin>
            <AggregatorPin
              imageUrl={homePin1}
              text="Research"
              kind="home"
              className={styles.aggregator}
            >
              <TransitionLink
                to="/researching"
                className="border-white text-white"
              >
                <span>Find out more</span>
              </TransitionLink>
            </AggregatorPin>
          </PinBlockGroup>
        </div>
      </MDXProvider>
    </div>
  )
}

IndexPageTemplate.propTypes = propTypes

const IndexPage = ({ data }) => {
  const { frontmatter } = data.page
  const { header } = frontmatter

  return (
    <LayoutFullWidth header={header}>
      <IndexPageTemplate data={data} />
    </LayoutFullWidth>
  )
}

IndexPage.propTypes = propTypes

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    page: mdx(frontmatter: { templateKey: { eq: "IndexPageTemplate" } }) {
      frontmatter {
        ...headerFields
        intro {
          title
          text
        }
        aggregatorsIntro {
          title
          content
        }
        topStory1 {
          title
          featuredImage
          url
        }
        topStory2 {
          title
          featuredImage
          url
        }
        topStory3 {
          title
          featuredImage
          url
        }
      }
    }
  }
`
